import React, { createContext, useCallback, useReducer, useState } from 'react';

import { InteractionRequiredAuthError } from '@azure/msal-browser';
import { useMsal } from '@azure/msal-react';

import { authReducer } from './authReducer';
import { types } from './types';
import { b2cPolicies, protectedResources } from '../../authConfig';
import { logger } from '../../utils/loggingUtils';

export const AuthContext = createContext();

const init = () => {
	const data = JSON.parse(localStorage.getItem('permissions'));
	return {
		userId: data?.id || '',
		isActive: data?.isActive || false,
		isLoggedIn: !!data,
		roles: data?.roles || [],
	};
};

export const AuthProvider = ({ children }) => {
	const { instance } = useMsal(),
		[authState, dispatch] = useReducer(authReducer, {}, () => init()),
		[cachedToken, setCachedToken] = useState(null),
		[tokenExpirationTime, setTokenExpirationTime] = useState(null),
		TOKEN_REFRESH_BUFFER_MS = 300000, // 5 minutes in milliseconds
		getAccessToken = useCallback(async () => {
			const currentTime = Date.now();
			// Return the cached token if it has more than TOKEN_REFRESH_BUFFER_MS left before expiration
			if (cachedToken && tokenExpirationTime && currentTime < tokenExpirationTime - TOKEN_REFRESH_BUFFER_MS) {
				return cachedToken;
			}

			const signUpSignInFlowRequest = {
				authority: b2cPolicies.authorities.signUpSignIn.authority,
				scopes: [...protectedResources.apis.scopes.read, ...protectedResources.apis.scopes.write],
			};

			try {
				const accessTokenResponse = await instance.acquireTokenSilent(signUpSignInFlowRequest);
				setCachedToken(accessTokenResponse.accessToken);
				setTokenExpirationTime(accessTokenResponse.expiresOn.getTime());
				return accessTokenResponse.accessToken;
			} catch (error) {
				logger.error('Silent token acquisition failed', error);
				if (error instanceof InteractionRequiredAuthError) {
					try {
						const response = await instance.ssoSilent(signUpSignInFlowRequest);
						setCachedToken(response.accessToken);
						setTokenExpirationTime(response.expiresOn.getTime());
						return response.accessToken;
					} catch (ssoError) {
						logger.error('SSO Silent token acquisition failed', ssoError);
						instance.loginRedirect(signUpSignInFlowRequest);
					}
				}
			}
			return null;
		}, [instance, cachedToken, tokenExpirationTime]),
		login = (data) => {
			localStorage.setItem('permissions', JSON.stringify(data));
			const action = { type: types.login, payload: data };
			dispatch(action);
		},
		logout = () => {
			localStorage.removeItem('permissions');
			const action = { type: types.logout };
			dispatch(action);
		},
		canAccess = (allowedRoles = []) => {
			const { roles } = authState;
			return allowedRoles.some((role) => roles.some((e) => e.roleName === role));
		},
		canEdit = () => {
			const { roles } = authState;
			return roles.some((e) => e.roleName === 'Admin' || e.roleName === 'Contributor');
		},
		toggleIsAuthorizingUser = (isAuthorizingUser) => {
			authState.isAuthorizingUser = isAuthorizingUser;
		},
		getUserId = () => {
			return authState.userId;
		},
		isAuthorizingUser = () => {
			return authState.isAuthorizingUser;
		},
		isLoggedIn = () => {
			return authState.isLoggedIn;
		},
		ROLESDATA = {
			Administrator: { RoleName: 'administrator', RoleDescription: 'Administrator' },
			ProjectManager: { RoleName: 'project_manager', RoleDescription: 'Project Manager' },
			Superintendent: { RoleName: 'superintendent', RoleDescription: 'Superintendent' },
			Installer: { RoleName: 'installer', RoleDescription: 'Installer' },
		};

	return (
		<AuthContext.Provider
			value={{
				...authState,

				// Methods
				canAccess,
				canEdit,
				login,
				logout,
				getAccessToken,
				getUserId,
				isAuthorizingUser,
				isLoggedIn,
				toggleIsAuthorizingUser,

				// Constants
				ROLESDATA,
			}}
		>
			{children}
		</AuthContext.Provider>
	);
};
