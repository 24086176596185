export const EntryProjectLogKeys = {
	ACTIVITY: 'activity',
	ACTIVITY_DATE: 'activityDate',
	HOURS_SPENT: 'hoursSpent',
	PROJECT: 'project',
	BUILDING_DIRECTION_1: 'buildingDirection1',
	BUILDING_DIRECTION_2: 'buildingDirection2',
	UNITS: 'units',
	UNIT_ACTIVITIES: 'unitActivities',
	QUANTITY_ENTERED: 'quantityEntered',
};

export const DEFAULT_PAGE_SIZE = 100;

export class EntryTimecardRequest {
	constructor({
		id,
		timecardDate,
		jobId,
		activityId,
		userId,
		createdBy,
		hours,
		isBillable,
		notes,
		attachments,
		isSubmitted,
	}) {
		this.id = id;
		this.timecardDate = timecardDate;
		this.jobId = jobId;
		this.activityId = activityId;
		this.userId = userId;
		this.createdBy = createdBy;
		this.hours = hours;
		this.isBillable = isBillable;
		this.notes = notes;
		this.attachments = attachments;
		this.isSubmitted = isSubmitted;
	}

	getBody() {
		return {
			id: this.id,
			timecardDate: this.timecardDate,
			jobId: this.jobId,
			activityId: this.activityId,
			userId: this.userId,
			createdBy: this.createdBy,
			hours: this.hours,
			isBillable: this.isBillable,
			notes: this.notes,
			attachments: this.attachments,
			isSubmitted: this.isSubmitted,
		};
	}
}

export class EntryObservationRequest {
	constructor({ id, observationDateTime, jobId, activityId, userId, createdBy, notes, attachments, isSubmitted }) {
		this.body = {};
		if (id) this.body['id'] = id.toString();
		this.body['observationDateTime'] = observationDateTime;
		this.body['jobId'] = jobId?.toString() ?? null;
		this.body['activityId'] = activityId.toString();
		this.body['userId'] = userId.toString();
		this.body['createdBy'] = createdBy.toString();
		this.body['notes'] = notes;
		this.body['attachments'] = attachments ?? [];
		this.body['isSubmitted'] = isSubmitted;
	}

	getBody() {
		return this.body;
	}
}
